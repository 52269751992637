import Main from './components/Main';

function App() {
  // hello there
  return (
    <div>
    <Main/>
    </div>
  );
}

export default App;
